<template>
  <a-modal
    v-model:visible="visible"
    class="add edit dialog public-modal"
    @close="handleCancel"
    unmount-on-close
    width="640px"
    :align-center="false"
    :mask-closable="false"
  >
    <template #title>
      <div>
        <span v-if="origFrom == 'relationGraph'">
          推进至 {{ assetName }}
        </span>
        <span v-else>{{ modalInfo[appCode].title }}</span>
        <span v-if="isAssets && isMultipleAsset && showAsset" class="asset-type-tag">{{
          parentView?.name
        }}</span>
      </div>
    </template>
    <div class="tabschoose">
      <template v-if="appCode === 'lpInvestor'">
        <div class="twocontent" v-show="showContent === 'first'">
          <a-form
            v-if="formsMetas.length > 0"
            v-model="runtime_value"
            class="edit-dialog-form"
            layout="vertical"
          >
            <template v-for="item in formsMetas" :key="item.key">
              <component
                ref="fields"
                :class="['custom-field', item?.class]"
                :project="project"
                :from="'dialog'"
                formType="vertical"
                :needDefault="true"
                :bCode="bCode"
                :currency="initCurrency"
                :is="getCompNameByType(item.fieldType)"
                :meta="item"
                :outerValue="runtime_value[item?.key]"
                :switchable="bCode !== 'fund' ?  true : false"
                :saveFileNoOperateEvent="saveFileNoOperateEvent"
                @updatePartData="updateData"
              ></component>
            </template>
          </a-form>
          <a-spin style="text-align: center; margin-top: 20px" v-else />
        </div>
        <div class="content" v-show="showContent === 'two'">
          <div class="top">
            <a-input
              ref="inputRef"
              :style="{
                background: '#fff',
                border: '1px solid #E4E5EB',
                padding: '0 7px',
                borderRadius: '4px',
                flex: '1',
              }"
              :class="[fouceshow ? 'inputFouce' : '']"
              :placeholder="modalInfo[appCode].placeholder"
              size="small"
              max-length="99"
              v-model="searchValue"
              @focus="focusSearch"
              @blur="
                () => {
                  showbord = false
                  fouceshow = false
                }
              "
              @clear="clearInput"
              @input="seachChange"
              @keyup.delete="keyDelete"
            >
              <template #suffix>
                <img
                  v-if="showbord"
                  @click="clenas"
                  class="clenas"
                  src="@/assets/img/file/clean.svg"
                  alt=""
                />
              </template>
            </a-input>
            <span v-if="clickshow" style="margin-left: 9px; color: #3272FE; font-size: 12px">{{
              $t('plat_c.allTabs.tabs.gongkaixinix')
            }}</span>
            <a-tooltip :content="$t('plat_c.app_c.dialog_add.gongkaixinxi_tip1')" position="bottom">
              <img v-if="clickshow" class="icbc" src="@/assets/img/file/tips.svg" alt="" />
            </a-tooltip>
          </div>
          <a-list
            v-if="listcontent.length > 0"
            hoverable="true"
            class="list"
            :loading="listLoading"
            :max-height="250"
            @reach-bottom="reachBottom"
          >
            <a-list-item
              style="border: 1px solid transparent"
              v-for="(item, index) in listcontent"
              @click="listItemClick(item, index)"
              :class="['list-item', clickFlag === index ? 'actived' : '']"
              :key="index"
            >
              <div class="list-item-left">
                <!-- <div class="showimg">
                  <img :src="item.logo ? item.logo : moren" alt="" />
                </div> -->
                <p>{{ item.name }}</p>
              </div>
              <div class="list-item-right">
                <p style="margin-top: 0">{{ item.full_name }}</p>
                <ul class="item-info">
                    <li>法定代表人：{{ item.legal_represent_name }}</li>
                    <li>注册资本：{{ item.tycRegCapital }}</li>
                    <li>成立日期：{{ item.establish_date }}</li>
                  </ul>
                <!-- <p>
                  <span v-if="item.address2 !== item.address3c && item.address1_cz3"
                    >{{ item.address1_cz2 }}/{{ item.address1_cz3 }}</span
                  >
                  <span v-else>{{ item.address1_cz2 }}</span>
                </p>
                <p v-if="!item.showtools" :ref="(ele) => (item.remarkDomRef = ele)">
                  {{ item.short_description }}
                </p>
                <a-tooltip v-else position="top" :content="item.short_description">
                  <p>{{ item.short_description }}</p>
                </a-tooltip> -->
              </div>
              <div v-if="clickFlag === index" id="triangle-topright"></div>
              <!-- <img v-if="clickFlag === index" src="@/assets/img/file/duigou.svg" alt=""> -->
              <i v-if="clickFlag === index" class="iconfont icon-close-med choos" />
            </a-list-item>
          </a-list>
          <template v-else>
            <div v-if="showDefaultText" class="creatnomore">
              <img src="../../assets/img/createNomore.png" alt="" />
              <p
                style="margin-top: 8px"
                v-html="$t('plat_c.app_c.dialog_add.touzizhezhuti_empty')"
              ></p>
            </div>
            <div v-else class="creatnomore">
              <img src="../../assets/img/createNomore.png" alt="" />
              <p
                style="margin-top: 8px"
                v-html="$t('plat_c.app_c.dialog_add.touzizhezhuti_notfund')"
              ></p>
            </div>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="content" v-if="showContent === 'first'">
          <div class="top">
            <a-input
              ref="inputRef"
              :style="{
                background: '#fff',
                border: '1px solid #E4E5EB',
                padding: '0 7px',
                borderRadius: '4px',
                flex: '1',
              }"
              :class="[fouceshow ? 'inputFouce' : '']"
              :placeholder="isFof ? '请输入基金全称' : modalInfo[appCode].placeholder"
              size="small"
              max-length="99"
              v-model="searchValue"
              @focus="focusSearch"
              @blur="
                () => {
                  showbord = false
                  fouceshow = false
                }
              "
              @clear="clearInput"
              @input="seachChange"
              @keyup.delete="keyDelete"
            >
              <template #suffix>
                <img
                  v-if="showbord"
                  @click="clenas"
                  class="clenas"
                  src="@/assets/img/file/clean.svg"
                  alt=""
                />
              </template>
            </a-input>
            <span v-if="clickshow" style="margin-left: 9px; color: #3272FE; font-size: 12px">{{
              $t('plat_c.allTabs.tabs.gongkaixinix')
            }}</span>
            <a-tooltip :content="$t('plat_c.app_c.dialog_add.gongkaixinxi_tip2')" position="bottom">
              <img v-if="clickshow" class="icbc" src="@/assets/img/file/tips.svg" alt="" />
            </a-tooltip>

          </div>
          <a-list
            v-if="listcontent.length > 0"
            hoverable="true"
            class="list"
            :loading="listLoading"
            :max-height="250"
            @reach-bottom="reachBottom"
          >
            <a-list-item
              style="border: 1px solid transparent"
              v-for="(item, index) in listcontent"
              :key="index"
              @click="listItemClick(item, index)"
              :class="[
                'list-item',
                clickFlag === index ? 'actived' : '',
                listcontent.length % 3 == 2 ? 'last-item' : '',
                isFof ? 'fof' : '',
              ]"
            >
              <div class="list-item-left">
                <!-- <div class="showimg">
                  <img :src="item.logo ? item.logo : moren" alt="" />
                </div> -->
                <p>{{ item.name }}</p>
              </div>
              <div class="list-item-right">
                <template v-if="isFof">
                  <p class="tag" v-if="item.fundNumber">
                    <span>{{ item.fundNumber }}</span>
                  </p>
                  <div class="desc">
                    <!-- <span>{{ renderFundType([item.fundType]) }}</span> -->
                    <span>{{ item.fundType }}</span>
                    <template v-if="item.orgName">
                      <span> | </span>
                      <span>{{ item.orgName }}</span>
                    </template>
                  </div>
                </template>
                <template v-else>
                  <p style="margin-top: 0">{{ item.full_name }}</p>
                  <ul class="item-info">
                    <li>法定代表人：{{ item.legal_represent_name }}</li>
                    <li>注册资本：{{ item.tycRegCapital }}</li>
                    <li>成立日期：{{ item.establish_date }}</li>
                  </ul>
                  <!-- <p v-if="item.address1_cz2">
                    <span v-if="item.address2 !== item.address3c && item.address1_cz3"
                      >{{ item.address1_cz2 }}/{{ item.address1_cz3 }}</span
                    >
                    <span v-else>{{ item.address1_cz2 }}</span>
                  </p>
                  <p v-if="!item.showtools" :ref="(ele) => (item.remarkDomRef = ele)">
                    {{ item.short_description }}
                  </p>
                  <a-tooltip v-else position="top" :content="item.short_description">
                    <p>{{ item.short_description }}</p>
                  </a-tooltip> -->
                </template>
              </div>
              <div v-if="clickFlag === index" id="triangle-topright"></div>
              <!-- <img v-if="clickFlag === index" src="@/assets/img/file/duigou.svg" alt=""> -->
              <i v-if="clickFlag === index" class="iconfont icon-close-med choos" />
            </a-list-item>
          </a-list>
          <template v-else>
            <div v-if="showDefaultText" class="creatnomore">
              <img src="../../assets/img/createNomore.png" alt="" />
              <p v-if="isFof" style="margin-top: 8px">请输入您想要查找的基金</p>
              <p
                v-else
                style="margin-top: 8px"
                v-html="$t('plat_c.app_c.dialog_add.zhutigongsi_empty')"
              ></p>
            </div>
            <div v-else class="creatnomore">
              <img src="../../assets/img/createNomore.png" alt="" />
              <p v-if="isFof" style="margin-top: 8px">没有找到您查找的基金</p>
              <p
                v-else
                style="margin-top: 8px"
                v-html="$t('plat_c.app_c.dialog_add.zhutigongsi_notfund')"
              ></p>
            </div>
          </template>
        </div>
        <div class="twocontent" v-else-if="showContent === 'two'">
          <!-- <a-spin v-if="showContent === 'first'" style="text-align: center; margin-top: 20px"  /> -->
          <a-form v-model="runtime_value" class="edit-dialog-form" layout="vertical">
            <template v-for="(item, index) in formsMetas" :key="item.key">
              <!-- <keep-alive> -->
              <component
               v-if="!item.hideedItem"
                ref="fields"
                :class="['custom-field', item?.fieldName, item?.class]"
                :from="'dialog'"
                :needDefault="true"
                formType="vertical"
                :bCode="bCode"
                :currency="initCurrency"
                :is="getCompNameByType(item.fieldType)"
                :meta="item"
                :outerValue="runtime_value[item?.key]"
                :switchable="bCode !== 'fund' ?  true : false"
                :saveFileNoOperateEvent="saveFileNoOperateEvent"
                @updatePartData="updateData"
              ></component>
              <!-- </keep-alive> -->
            </template>
          </a-form>
        </div>
      </template>
      <div class="threecontent" v-show="showContent === 'three'">
        <ProjectMembers
          :selectTeamList="selectTeamList"
          @choosePerId="choosePerId"
          @updatePermission="updatePermission"
          @chartChange="chartChange"
        ></ProjectMembers>
      </div>
    </div>
    <template #footer>
      <div class="zidngyifooter">
        <div class="zidngyifooter-left">
          <div class="firststep" v-if="showFirst">
            <span :class="[showContent === 'first' ? 'active' : '']">
              <!-- <i
                v-if="['two', 'three'].includes(showContent)"
                class="iconfont icon-close-med contentIcon sizecolor"
              /> -->
              <img
                style="width: 10px; height: 10px"
                v-if="['two', 'three'].includes(showContent)"
                src="@/assets/img/file/duigou.svg"
                alt=""
              />
              <i v-else class="contentIcon">1</i>
            </span>
            <!-- <i class="iconfont icon-close-med" /> -->
            <span> {{ modalInfo[appCode].stepOneText }} </span>
            <i class="iconfont icon-Steps_2 gt"></i>
          </div>
          <div class="firststep" v-if="!(appCode === 'lpInvestor' && project)">
            <span :class="[showContent === 'two' ? 'active' : '']">
              <!-- <i
                v-if="['three'].includes(showContent)"
                class="iconfont icon-close-med contentIcon sizecolor"
              /> -->
              <img
                style="width: 10px; height: 10px"
                v-if="['three'].includes(showContent)"
                src="@/assets/img/file/duigou.svg"
                alt=""
              />
              <i v-else class="contentIcon">{{
                !showFirst ? '1' : '2'
              }}</i>
            </span>
            <!-- <i v-else class="iconfont icon-icon_business" /> -->
            <span> {{ modalInfo[appCode].stepTwoText }} </span>
            <i class="iconfont icon-Steps_2 gt"></i>
          </div>
          <div class="firststep">
            <span :class="[showContent === 'three' ? 'active' : '']">
              <i class="contentIcon">{{ !showFirst ? '2' : '3' }}</i>
            </span>
            <span> {{ modalInfo[appCode].stepThreeText }} </span>
          </div>
        </div>
        <div>
          <a-button v-show="lastStep" class="handleClose" type="outline" @click="lasthandleOk">{{
            $t('plat_c.app_c.general_btn.shangyibu')
          }}</a-button>
          <a-button
            v-show="['first', 'two'].includes(showContent)"
            class="handleOk"
            :loading="nextStepLoading"
            @click="handleOk"
            >{{ $t('plat_c.app_c.general_btn.xiayibu') }}</a-button
          >
          <a-button
            :loading="loading"
            v-show="['three'].includes(showContent)"
            class="handleOk"
            @click="handleOk"
            >{{ $t('plat_c.app_c.general_btn.wancheng') }}</a-button
          >
        </div>
      </div>
    </template>
  </a-modal>
  <!-- 弹窗 -->
  <showeQuallyDialog
    :currName="searchValue"
    :quallyList="quallyList"
    v-if="showequally"
    v-model:showequally="showequally"
    @beginTo="beginTo"
  ></showeQuallyDialog>
</template>

<script>
import { getCompNameByType, compList } from '@/components/form/fields/indexAll'
import ProjectMembers from '../components/projectMembers/index.vue'
import { getInvestedCompByName } from '@/services/investment/index.js'
import imgs from '@/assets/img/jinghzun.png'
import store from '@/store/index'
import { mapMutations } from 'vuex'
import {
  beforeProjectShareAdd,
  beforeProjectAdd,
  queryCreateRequiredList,
  companySearch,
  fundSearch,
  findAreaTree,
  getSelfInfo,
  checkIsExist,
  queryLatestDataRights,
  insightSearchByCompanyName,
} from '@/services/investment/createProject.js'
import { transformType } from '@/components/transformType/detail.js'
import { chatCreate } from '@/services/investment/chat.js'
import _ from 'lodash'
import dayjs from 'dayjs'
import { getDataTypeByBusinessCode } from '@/utils/enums.js'
import { FUND_TYPE_OBJ } from '@/assets/constants/mappings.js'
import showeQuallyDialog from '@/views/beforeInv/edit/components/showequallyDialog.vue'
import xss from 'xss'
import { mapState } from 'vuex'
import { currencyMappingStore } from '@/store/useCurrencyStore'
import { formatReportType } from '@/views/dataCollect/format.js'

export default {
  name: 'AddEditDialog',
  // props: ['appCode', 'bCode', 'project', 'parentView', 'isMultipleAsset'],
  props: {
    appCode: String,
    bCode: String,
    project: Object,
    parentView: Object,
    isMultipleAsset: Boolean,
    saveFileNoOperateEvent: {
      type: Boolean,
      default: false
    },
    showAsset: {
      type: Boolean,
      default: false
    },
    customSearchValue: String,
    origFrom:{
      type: String,
      default: 'desk', // 默认是台账的新建项目。shareDesk: 项目分享台账
    },
    initiator: String, // 兼容项目分享台账 -项目原分享人
    shareInstanceId: String, // 兼容项目分享台账 -分享实例的ID
    assetName: String, //
    addAormsMetas:Array, //子基金遴选创建项目创建的时候额外的字段
    parseId:String, //ai解析的id
  },
  components: {
    ...compList,
    ProjectMembers,
    showeQuallyDialog,
  },
  data() {
    return {
      // modalInfo,
      // showDefaultText: true,
      visible: true,
      showtools: false,
      fouceshow: false,
      searchValue: '',
      currName: '',
      showContent: 'first',
      chooseValue: 'A',
      // listcontent: [{}, {}, {},{},{}],
      listcontent: [],
      companyFullNameParams: {},
      insightId: '',
      areasParams: [],
      formsData: {},
      runtime_value: {
        currencyUnit: 'CNY',
      },
      clickFlag: -1,
      clickshow: false,
      moren: imgs,
      formsMetas: [],
      areas: [],
      selectTeamList: [],
      showbord: false,
      chartFlag: false,

      // 投资者
      form: {
        name: '',
        status: '',
        typeId: '',
      },
      loading: false,
      FUND_TYPE_OBJ,
      showequally: false,
      quallyList: [],
      initCurrency:'CNY',
      creditCode: undefined,
      brandId: undefined,
      listLoading: false,
      baseCurrencyUnit: 'CNY', //本位币
      nextStepLoading: false,
      searchTime: 0,
    }
  },
  computed: {
    ...mapState(['userSelfInfo']),
    isAssets() {
      return !['fund', 'lpInvestor'].includes(this.appCode)
    },
    isCoin() {
      return this.parentView && this.createBCode.toLowerCase().indexOf('coin') > -1
    },
    isFof() {
      return this.parentView && this.createBCode.toLowerCase().indexOf('fof') > -1
    },
    assetTypeId() {
      return this.isAssets ? this.parentView.id : undefined
    },
    createBCode() {
      return this.parentView?.businessCode || this.bCode
    },
    modalInfo() {
      return {
        beforeProject: {
          title: this.$t('plat_c.app_c.dialog_add.chuangjianxiangmu'),
          placeholder: this.$t('plat_c.app_c.dialog_add.zhutigongsi_placeholder'),
          stepOneText: this.$t('plat_c.app_c.dialog_add.zhutigongsi'),
          stepTwoText: this.$t('plat_c.app_c.dialog_add.jibenxinxi'),
          stepThreeText: this.$t('plat_c.app_c.dialog_add.xiangmuchengyuan'),
        },
        investedCompany: {
          title: this.$t('plat_c.app_c.dialog_add.chuangjianxiangmu'),
          placeholder: this.$t('plat_c.app_c.dialog_add.zhutigongsi_placeholder'),
          stepOneText: this.$t('plat_c.app_c.dialog_add.zhutigongsi'),
          stepTwoText: this.$t('plat_c.app_c.dialog_add.jibenxinxi'),
          stepThreeText: this.$t('plat_c.app_c.dialog_add.xiangmuchengyuan'),
        },
        fund: {
          title: this.$t('plat_c.app_c.dialog_add.chuangjianjijin'),
          placeholder: '',
          stepOneText: '',
          stepTwoText: this.$t('plat_c.app_c.dialog_add.jibenxinxi'),
          stepThreeText: this.$t('plat_c.app_c.dialog_add.xiangmuchengyuan'),
        },
        lpInvestor: {
          title: this.$t('plat_c.app_c.dialog_add.tianjiatouzizhe'),
          placeholder: this.$t('plat_c.app_c.dialog_add.touzizhezhuti_placeholder'),
          stepOneText: this.$t('plat_c.app_c.dialog_add.jibenxinxi'),
          stepTwoText: this.$t('plat_c.app_c.dialog_add.zhutiquancheng'),
          stepThreeText: this.$t('plat_c.app_c.dialog_add.xiezuochengyuan'),
        },
      }
    },
    showDefaultText() {
      return this.listcontent.length || this.searchValue === ''
    },
    lastStep() {
      if (!this.showFirst) {
        return ['three'].includes(this.showContent)
      } else {
        return ['two', 'three'].includes(this.showContent)
      }
    },
    showFirst() {
      if (
        (this.appCode === 'fund' ||
        this.isFof ||
        (this.project && this.appCode !== 'lpInvestor') ||
        (this.isAssets && this.isCoin))
      ) {
        return false
      } else {
        return true
      }
    },
  },
  watch: {
    listcontent: {
      deep: true,
      handler(v) {
        // console.log(this.listcontent)
        this.listcontent.forEach((ele) => {
          if (ele.remarkDomRef) {
            let el = ele.remarkDomRef
            // console.log(el.clientHeight, el.scrollHeight, 1)
            const clientHeight = el.clientHeight
            const scrollHeight = el.scrollHeight
            // console.log(scrollHeight,clientHeight);
            if (scrollHeight > clientHeight) {
              ele.showtools = true
            } else {
              ele.showtools = false
            }
          }
        })
      },
    },
    customSearchValue: {
      immediate: true,
      handler(v) {
        if(v) {
          this.searchValue = v
          if(!this.showFirst) {
            this.runtime_value = {
              ...this.runtime_value,
              ...this.project,
            }
          } else {
            this.seachChange(v)
          }
        }
      }
    }
  },
  async created() {
    const {currencySymbolList} = currencyMappingStore();
    this.baseCurrencyUnit = (currencySymbolList || []).filter((item) => item.isBase)[0]?.englishName || 'CNY'
    this.setCurrencyUnit(this.baseCurrencyUnit)
    this.initCurrency = this.baseCurrencyUnit
    this.runtime_value.currencyUnit = this.baseCurrencyUnit;
    if (this.appCode === 'lpInvestor') {
      this.runtime_value.name = this.project?.projectName ?? null
    }
    if (!this.showFirst) {
      this.showContent = 'two'
      this.runtime_value['projectName'] = this.project?.projectName
      if (this.parentView?.businessCode?.toLowerCase().indexOf('coin') > -1) {
        this.runtime_value['companyFullName'] = this.project?.companyFullName
      }
    }


    if (this.origFrom == 'relationGraph') { // 关系网-推进至
      this.showContent = 'two'
      this.getInvestedComp(true, true)
    }

    this.searchfindChildOptions()
    let res = await queryCreateRequiredList({
      params: { businessCode: this.createBCode, assetTypeId: this.assetTypeId },
    })
    // 因为币权和子基金不区分投前投后，v2.3.2 只有投前所有资产需要‘保密项目’字段 
    if(res && ['afterCoin', 'afterFof'].includes(this.createBCode)){
      res = res.filter((ii)=>{
        return ii.fieldName != 'secretState';
      })
    }

    // let userInfo = await getSelfInfo()
    let userInfo = this.userSelfInfo
    if (!userInfo) return
    userInfo.permission = '2'
    userInfo.type = 1
    userInfo.memberType = 1
    userInfo.memberName = userInfo?.name
    this.selectTeamList.unshift(userInfo)
    this.getHistoryTeamList() // 获取历史添加成员信息
    if(this.origFrom=='aIAnalysisPopNew'){
      let addAormsMetas=_.cloneDeep(this.addAormsMetas)
      addAormsMetas.map((item)=>{
        this.runtime_value[item.fieldName]=item.value
        if(item.aiType==1){
          if(item.orginTAype) {
            item.type=item.orginTAype
          }
          // let find=res.find(f=>f.columnName==item.columnName)
          // if(!find){
          //   res.push(item)
          // }
        }
      })
    }

    this.formsMetas = await transformType(res, this.appCode)
    console.log(this.formsMetas,'formsMetas')
    if (this.bCode === 'lpInvestor') {
      this.formsMetas = this.formsMetas.filter((ele) => ele?.fieldName !== 'fullName')
    } else if (!this.isCoin) {
      this.formsMetas = this.formsMetas.filter((ele) => ele?.fieldName !== 'companyFullName')
    }

    this.formsMetas.forEach((ele) => {
      if (ele.fieldType == 'tagselect') {
        ele.showAvatar = false
      } else if (ele.fieldType == 'employee') {
        ele.showAvatar = true
      }
      if (ele.key == 'currencyUnit') {
        ele.showSearch = true
      } else {
        ele.showSearch = false
      }
    })
  },
  mounted() {
    this.aotuFocus()
  },
  methods: {
    ...mapMutations(['setCurrencyUnit']),
    aotuFocus() {
      this.$nextTick(() => {
        this.$refs.inputRef?.focus()
      })
    },
    getHistoryTeamList() {
      // 获取历史添加成员
      queryLatestDataRights({
        params: {
          assetTypeId: ['fund', 'lpInvestor'].includes(this.bCode) ? null : this.assetTypeId,
          businessCode: this.bCode
        }
      }).then(res => {
        res = res.filter(item => {
          item.name = item.name || item.memberName
          item.id = item.memberId
          return item.memberType != 1 // 过滤负责人
        })
        this.selectTeamList.push(...res)
        this.selectTeamList = _.uniqBy(this.selectTeamList, 'id')
        this.selectTeamList = this.selectTeamList.filter(item => item.memberName) // 调整组织架构后，有的部门名称接口返回 null 了，和娟姐龙哥协商后 前端先过滤掉，后期龙哥优化
      })
    },
    chartChange(val) {
      this.chartFlag = val
    },
    focusSearch() {
      this.fouceshow = true
    },
    visibilityChange(e) {
      let el = e.target
      const clientWidth = el.clientWidth
      const scrollWidth = el.scrollWidth
      if (scrollWidth > clientWidth) {
        this.showtools = true
      }
      // console.log(clientWidth, scrollWidth)
    },
    getwid(index) {
      // console.log(name,index);
      let str = 'itemref' + index
      this.$nextTick(() => {
        const clientWidth = this.$refs.str?.clientWidth
        const scrollWidth = this.$refs.str?.scrollWidth
        // console.log(clientWidth,scrollWidth);
      })
    },
    updatePermission(com, id, idx) {
      if (com === '0') {
        this.selectTeamList.filter((item) => {
          if (item['memberType'] === 1) {
            item['memberType'] = 2
            item['permission'] = 2
          }
        })
        this.selectTeamList[idx]['memberType'] = 1
        this.selectTeamList[idx]['permission'] = 2
      } else if (com == '1') {
        this.selectTeamList[idx]['memberType'] = 2
        this.selectTeamList[idx]['permission'] = com
      } else if (com === '2') {
        this.selectTeamList[idx]['memberType'] = 2
        this.selectTeamList[idx]['permission'] = com
      } else if (com === '3') {
        this.selectTeamList.splice(idx, 1)
      }
    },
    choosePerId(val) {
      this.selectTeamList = [...this.selectTeamList, ...val]
    },
    seachChange: _.debounce(async function(val){
      this.listLoading = true
      this.runtime_value = {}
      this.runtime_value.currencyUnit = this.baseCurrencyUnit;
      if (val.trim() !== '') {
        this.showbord = true
      }
      if (this.isFof) {
        const res = await fundSearch({ params: { keyword: val.trim() } })
        this.listLoading = false
        // if (!res) return

        this.listcontent = res ? [...res] : []
        this.listcontent.forEach((ele, index) => {
          // console.log(ele.remarkDomRef)
          if (ele.remarkDomRef) {
            let el = ele.remarkDomRef
            // console.dir(el, el.clientHeight, el.scrollHeight)
            const clientHeight = el.clientHeight
            const scrollHeight = el.scrollHeight
            // console.log(scrollHeight,clientHeight);
            if (scrollHeight > clientHeight) {
              ele.showtools = true
            }
          }
          // this.getwid(index)
          ele.address1_cz1 = this.getTreeName(this.areas, ele?.address1)
          ele.address1_cz2 = this.getTreeName(this.areas, ele?.address2)
          ele.address1_cz3 = this.getTreeName(this.areas, ele?.address3)
          ele.name = ele.fundName
        })
      } else {
        let res = await companySearch({ params: { keyword: val.trim() } })
        this.listLoading = false
        // if (!res) return
        this.listcontent = res ? [...res] : []
        this.listcontent.forEach((ele, index) => {
          // console.log(ele.remarkDomRef)
          if (ele.remarkDomRef) {
            let el = ele.remarkDomRef
            // console.dir(el, el.clientHeight, el.scrollHeight)
            const clientHeight = el.clientHeight
            const scrollHeight = el.scrollHeight
            // console.log(scrollHeight,clientHeight);
            if (scrollHeight > clientHeight) {
              ele.showtools = true
            }
          }
          // this.getwid(index)
          ele.address1_cz1 = this.getTreeName(this.areas, ele?.address1)
          ele.address1_cz2 = this.getTreeName(this.areas, ele?.address2)
          ele.address1_cz3 = this.getTreeName(this.areas, ele?.address3)
        })
      }
      if(this.searchTime == 0 && this.listcontent?.length) {
        const itemIndex = this.listcontent.findIndex((list) => {
          return list.full_name === this.customSearchValue || list.fundName === this.customSearchValue
        })
        if(itemIndex > -1) {
          this.listItemClick(this.listcontent[itemIndex], itemIndex)
        }
      }
      if (this.origFrom == 'relationGraph') { // 关系网-推进至
        this.runtime_value['projectName'] = xss(this.searchValue && this.searchValue.trim()) // 第二步回写项目简称
        this.runtime_value['companyFullName'] = xss(this.searchValue && this.searchValue.trim()) // 第二步回写项目简称
        this.companyFullNameParams['companyFullName'] = xss(this.searchValue)
      }
      this.runtime_value = {
        ...this.runtime_value,
        ...this.project,
      }
      this.searchTime++
    }, 500),
    async searchfindChildOptions() {
      let res = await findAreaTree({ data: { type: 'country' } })
      if (!res) return
      this.areas = res
    },
    getTreeName(list, id) {
      let _this = this
      for (let i = 0; i < list.length; i++) {
        let a = list[i]
        if (a.value === id) {
          return a.label
        } else {
          if (a.children && a.children.length > 0) {
            let res = _this.getTreeName(a.children, id)
            if (res) {
              return res
            }
          }
        }
      }
    },
    renderFundType(item) {
      const targetList = (item || []).reduce((res, v) => {
        res.push(this.FUND_TYPE_OBJ[v])
        return res
      }, [])
      return targetList.join(',')
    },
    updateData(data, meta) {
      if (meta.fieldName === 'currencyUnit') {
        this.setCurrencyUnit(data['currencyUnit'])
        this.initCurrency = data['currencyUnit']
      }
      Object.assign(this.runtime_value, data)
    },
    getCompNameByType(type) {
      return getCompNameByType(type)
    },
    clearInput() {
      this.clickFlag = -1
      this.clickshow = false
      this.listcontent = []
      this.showbord = false
      this.runtime_value = {}
    },
    keyDelete: _.debounce(function(e){
      this.clickFlag = -1
      this.clickshow = false
      this.currName = ''
      this.companyFullNameParams = {}
      this.areasParams = []
    },500),
    listItemClick(item, index) {
      this.creditCode = item.creditNumber ?? undefined 
      this.brandId = item.brandId ?? undefined 
      if (this.clickFlag === index) {
        this.clickFlag = -1
        this.clickshow = false
        // this.searchValue = item.full_name
        this.companyFullNameParams = {}
        this.areasParams = []
        if(this.origFrom == 'shareDesk'){this.sameparams()} 
        this.runtime_value = this.origFrom == 'shareDesk' ? { ...this.project } : {}
      } else {
        this.clickFlag = index
        this.clickshow = true
        if (this.isFof) {
          this.searchValue = item.fundName
          this.currName = item.fundName
          const defaultParams = this.origFrom == 'shareDesk' ? { ...this.project } : {}
          this.companyFullNameParams = {
            ...defaultParams,
            logo: item.logo,
            fundFullName: item.fundName,
            establishmentTime: item.establishDate ? dayjs(item.establishDate).valueOf() : '',
            fundNum: item.fundNumber,
            // fundType: FUND_TYPE_OBJ[item.fundType],
            fundType: item.fundType,
            introduction: item.shortDescription,
            filingTime: item.regDate ? dayjs(item.regDate).valueOf() : '',
            fundManager: item.fundManager,
            insightId: item.id,
            orgId: item.orgId,
          }
        } else {
          this.searchValue = item.full_name
          this.currName = item.name
          const defaultParams = this.origFrom == 'shareDesk' ? { ...this.project } : {}

          this.companyFullNameParams = {
            ...defaultParams,
            insightId: item.id,
            companyFullName: item.full_name,
            logo: item.logo,
            projectIntroduce: item.short_description,
            establishDate: item.establish_date ? dayjs(item.establish_date).valueOf() : '',
          }
          // if (item.address2 === item.address3) {
          //   this.areasParams = [item.address1, item.address2]
          // } else {
          //   this.areasParams = [item.address1, item.address2, item.address3]
          // }
          this.areasParams =(item.address1 || item.address2) ? [item.address1, item.address2] : null // 后端获取不到address3，所以不传了
        }
      }
    },
    reachBottom() {
      // console.log('触底了')
    },
    lasthandleOk() {
      switch (this.showContent) {
        case 'two':
          this.showContent = 'first'
          break
        case 'three':
          if (this.appCode === 'lpInvestor' && this.project) {
            this.showContent = 'first'
          } else {
            this.showContent = 'two'
          }
          break
      }
    },
    async doValidate() {
      const { fields } = this.$refs
      await Promise.all(fields.map((field) => field.doValidate()))

      let formsData = fields.reduce((pre, cur) => {
        let data = cur.getKV()
        Object.assign(pre, data) //getKV返回的均为{key: value}格式
        return pre
      }, {})
      // console.log(formsData);
      const datas = this.origFrom == 'shareDesk'? this.project?.extendDatas : {}
      let allFileds = {
        extendDatas: {...datas},
        fileDatas: {},
      }
      if(this.origFrom=='aIAnalysisPopNew'){
        let zibiao=[]
        let addAormsMetas=_.cloneDeep(this.addAormsMetas)
        addAormsMetas.map((item)=>{
          let find=this.formsMetas.find(f=>f.columnName==item.columnName)
          if(!find){
            item.hideedItem = true
            if (item.aiType == 1) {
              // if(item.orginTAype) {
              //   item.type=item.orginTAype
              // }
              if ([ 'currency', 'file',  'multiSelect'].includes(item.type) && item.value) {
              // if (['linkageArea', 'linkageCategory', 'currency', 'select', 'file', 'employee', 'externalData', 'multiSelect'].includes(item.type) && item.value) {
                if (item.type === 'currency') {
                  item.value.equivalentMoney = item.value.num;
                  if (item.value.num == '') {
                    item.value = null;
                  }
                }
                // 转json字符串
                item.value = JSON.stringify(item.value);
              }

              if (item.type === 'select') {
                item.value = item.value+'';
              }
              // // child.value10 = arr.dataReport // 后端接口指定value10存放报告期
              // if (item.type === 'date' && item.value) {
                // item.value = dayjs(item.value).valueOf();
              // }
              // if (['number', 'percentage'].includes(item.type) && item.value) {
                // item.value = item.value + ''; // 数字转字符串
              // }
              if (['', null, undefined, 'null', '[]'].includes(item.value)) {
                item.value = null;
              }
              this.formsMetas.push(item);
            }
            else{
              item.fieldList?.pop(); // 去掉action操作列
              let dataReport=null
              if (item.needReport ) {
                let timeStr = `${item.reportYear}-${item.reportQut}`;
                dataReport = dayjs(timeStr).valueOf() 
                item['reportDate']=dataReport
                item['reportType']=formatReportType(dataReport)
              } item?.fieldList.forEach(fieldItem => {
              item?.extendDataList?.map((ii, index) => {
                delete ii[""]
                if (item.needReport ) {
                  // if(fieldItem.fieldName == 'reportDate'){
                    // }
                    if(item.label=='投资标的'||item.objectName=='投资标的'){
                      ii['value10'] = dataReport + '';// 后端接口指定value10存放报告期
                    }else{
                      ii['reportDate'] = dataReport + '';
                    }
                }
                if (['linkageArea', 'linkageCategory', 'currency', 'select', 'file', 'employee', 'externalData', 'multiSelect'].includes(fieldItem.type) && ii[fieldItem.fieldName]) {
                  if (fieldItem.type === 'employee') {
                    ii[fieldItem.fieldName] = ii[fieldItem.fieldName];
                  }
                  if (fieldItem.type === 'externalData') {
                    ii[fieldItem.fieldName] = ii[fieldItem.fieldName].relatedObj;
                  }
                  if (fieldItem.type === 'select') {
                    ii[fieldItem.fieldName] = Object.prototype.toString.call(ii[fieldItem.fieldName]) === '[object Array]'?ii[fieldItem.fieldName]:[ii[fieldItem.fieldName]];
                    // ii[fieldItem.fieldName] = [ii[fieldItem.fieldName]]
                  }
                  if (fieldItem.type === 'currency') {
                    ii[fieldItem.fieldName].equivalentMoney = ii[fieldItem.fieldName]?.num || 'CNY'; 
                    if (ii[fieldItem.fieldName]?.num == '') {
                      ii[fieldItem.fieldName] = null;
                    }
                  }
                  // 转json字符串
                  ii[fieldItem.fieldName] = JSON.stringify(ii[fieldItem.fieldName]);
                }
               
                // child.value10 = arr.dataReport // 后端接口指定value10存放报告期
                if (fieldItem.type === 'date' && ii[fieldItem.fieldName]) {
                  ii[fieldItem.fieldName] = dayjs(ii[fieldItem.fieldName]).valueOf() + '';
                }
                if (['number', 'percentage'].includes(fieldItem.type) && ii[fieldItem.fieldName]) {
                  ii[fieldItem.fieldName] = ii[fieldItem.fieldName] + ''; // 数字转字符串
                }
                if (['', null, undefined, 'null', '[]'].includes(ii[fieldItem.fieldName])) {
                  ii[fieldItem.fieldName] = null;
                }
              });
            });
              zibiao.push(item)
            }
          }
        })
        allFileds.aiAnalysisRO={
          parseFrom:1,
          // reportType
          assetTypeId:this.assetTypeId,
          businessCode:this.bCode,
          parseId:this.parseId,
          items:zibiao
        }
      }
      this.formsMetas.forEach((el) => {
        const key = el.fieldName
        let value = formsData[key]
        if(el.hideedItem){
          value=el.value
        }

        if (el.tenantId == 'systemId'|| (el?.businessCode !=='extendData') ) {
          // 系统字段
          allFileds[key] = value
        } else {
          // 自定义字段
          if ([null, undefined].includes(value) || value?.length === 0) {
            allFileds.extendDatas[key] = null
          } else if (el.fieldType === 'file') {
            allFileds.fileDatas[key] = value
          } else if (typeof value != 'string') {
            allFileds.extendDatas[key] = JSON.stringify(value)
          } else if (el.fieldType == 'text') {
            // console.log('=======', el.fieldType, value);
            allFileds.extendDatas[key] = xss(value)
          } else if (el.fieldType == 'textarea') {
            // console.log('===333333====', el.fieldType, value);
            allFileds.extendDatas[key] = xss(value)
          } else {
            allFileds.extendDatas[key] = value
          }
        }
        
      })
      
      
      // let isExtendData = this.formsMetas.filter((ele) => ele.tenantId !== 'systemId') || []
      // let systemIdData = this.formsMetas.filter((ele) => ele.tenantId == 'systemId') || []
      // Object.keys(formsData).forEach((item) => {
      //   isExtendData.forEach((ele) => {
      //     if (item === ele.fieldName) {
      //       if ([null, undefined].includes(formsData[item]) || formsData[item]?.length === 0) {
      //         allFileds.extendDatas[item] = null
      //       } else if (typeof formsData[item] != 'string') {
      //         allFileds.extendDatas[item] = JSON.stringify(formsData[item])
      //       } else {
      //         allFileds.extendDatas[item] = formsData[item]
      //       }
      //     }
      //   })
      //   systemIdData.forEach((ele) => {
      //     if (item === ele.fieldName) {
      //       allFileds[item] = formsData[item]
      //     }
      //   })
      // })
      // console.log(allFileds);
      const params = {
        assetTypeId: this.assetTypeId,
      }
      if (this.createBCode === 'lpInvestor') {
        params.investorName = xss(formsData.name)
      } else if (this.createBCode === 'fund') {
        params.fundName = xss(formsData.fundName)
      } else {
        params.projectName = xss(formsData.projectName)
      }
      // 检查名称重复
      checkIsExist(
        {
          data: params,
        },
        this.createBCode,
      ).then((res) => {
        if (this.appCode === 'lpInvestor' && !this.project) {
          this.showContent = 'two'
          this.aotuFocus()
        } else {
          // 子基金将校验主体相同放到第二步
          // 子基金在校验完必填项后，需要校验主体是否相同
          if(this.isFof) {
            this.getInvestedComp(false)
          } else {
            // 公开信息新建投资者
            this.showContent = 'three'
          }
          
        }
      })
      if (this.appCode === 'lpInvestor') {
        this.formsData = Object.assign({}, allFileds)
      } else {
        this.formsData = Object.assign({}, this.companyFullNameParams, allFileds)
      }
    },
    async getInvestedComp(writeBackName = true, goPrev = false,) {
      console.log(this.runtime_value, 'this.runtime_value')
      this.nextStepLoading = true
      try {
        let params = {
          companyFullName: xss(this.searchValue),
          assetTypeId: this.parentView.id
        }
        if (this.bCode === 'lpInvestor') {
          params = {
            fullName: xss(this.searchValue)
          }
        } else if (['beforeFof', 'afterFof'].includes(this.bCode)) {
          params = {
            fundFullName: this.runtime_value?.fundFullName,
            assetTypeId: this.parentView.id
          }
          this.searchValue = this.runtime_value?.fundFullName
        }
        let res = await getInvestedCompByName(
          { data: params },
          this.bCode,
        )
        this.nextStepLoading = false
        this.quallyList = res ?? []
        if (res?.length > 0) {
          if (goPrev) this.showContent = 'first'
          this.showequally = true
        } else {
          this.showequally = false
          writeBackName && this.sameparams()
          this.showContent = (this.appCode === 'lpInvestor' || this.isFof) ? 'three' : 'two'
        }
      } catch (error) {
        this.nextStepLoading = false
      }
    },
    beginTo() {
      this.clickFlag != -1 && this.sameparams()
      this.showContent = (this.appCode === 'lpInvestor' || this.isFof) ? 'three' : 'two'
      this.showequally = false
    },
    sameparams() {
      // 回写项目简称
      if (['beforeProject', 'investedCompany'].includes(this.bCode) && this.searchValue) {
        insightSearchByCompanyName(xss(this.searchValue)).then(res => {
          this.runtime_value['projectName'] =  this.project?.projectName ??  res?.name ?? ''
          if (!this.runtime_value['area']?.length) {
            this.runtime_value['area'] = []
            res?.address1 && this.runtime_value['area'].push(res.address1)
            res?.address2 && this.runtime_value['area'].push(res.address2)
            res?.address3 && this.runtime_value['area'].push(res.address3)
          } else {
            if (res?.address1) this.runtime_value['area'][0] = res.address1
            if (res?.address2) this.runtime_value['area'][1] = res.address2
            if (res?.address3) this.runtime_value['area'][2] = res.address3
          }
        })
      } else {
        this.runtime_value['projectName'] = xss(this.currName)
      }
      this.runtime_value['area'] = this.areasParams
      this.runtime_value['projectIntroduce'] = this.companyFullNameParams?.projectIntroduce || ''
      this.runtime_value['introduction'] = this.companyFullNameParams?.projectIntroduce || ''
      // this.runtime_value['industy'] = this.currName
      if(this.origFrom == 'shareDesk') {
        this.runtime_value = {
          ...this.runtime_value,
          ...this.project,
        }
      }

    },
    async handleOk() {
      switch (this.showContent) {
        case 'first':
          if (this.appCode === 'lpInvestor') {
            this.doValidate()
          } else {
            if (!this.searchValue)
              return this.$Message.error(
                this.isFof ? '请选择基金' : this.$t('plat_c.app_c.dialog_add.zhutigongsi_toast'),
              )
            if (this.clickFlag === -1) {
              if (this.isFof) {
                this.companyFullNameParams['fundFullName'] = xss(this.searchValue && this.searchValue.trim())
              } else {
                this.companyFullNameParams['companyFullName'] = xss(this.searchValue && this.searchValue.trim())
              }
              this.runtime_value['projectName'] = xss(this.searchValue && this.searchValue.trim()) // 第二步回写项目简称
              if (!['beforeCoin', 'afterCoin', 'fund'].includes(this.bCode)) { // 子基金和基金不需要重复主体提示
                this.getInvestedComp(false)
              } else {
                this.showContent = 'two'
              }
            } else {
              if (!['beforeCoin', 'afterCoin', 'fund'].includes(this.bCode)) { // 子基金和基金不需要重复主体提示
                this.getInvestedComp()
              } else {
                if (this.isFof) {
                  this.runtime_value = {
                    ...this.runtime_value,
                    projectName: xss(this.currName),
                    fundFullName: this.companyFullNameParams.fundFullName || '',
                    establishmentTime: this.companyFullNameParams.establishmentTime || '',
                    fundNum: this.companyFullNameParams.fundNum || '',
                    fundType: this.companyFullNameParams.fundType,
                    introduction: this.companyFullNameParams.introduction,
                    filingTime: this.companyFullNameParams.filingTime || '',
                    fundManager: this.companyFullNameParams.fundManager,
                  }
                } else {
                  this.sameparams()
                }
                this.showContent = 'two'
              }
              
            }
          }
          break
        case 'two':
          if (this.appCode === 'lpInvestor') {
            if (this.clickFlag === -1) {
              this.runtime_value['projectName'] = xss(this.searchValue)
              this.companyFullNameParams['companyFullName'] = xss(this.searchValue)
              this.getInvestedComp(false)
            } else {
              this.getInvestedComp()
              this.runtime_value['projectName'] = xss(this.currName)
            }
            this.formsData = Object.assign({}, this.formsData, this.companyFullNameParams)
            // this.showContent = 'three'
          } else {
            this.doValidate()
          }
          break
        case 'three':
          if (this.loading) return
          this.loading = true
          this.createdProject()
      }
    },
    async createdProject() {
      let projectMemberList = []
      this.selectTeamList.forEach((item) => {
        let obj = {}
        obj = {
          memberId: item.id,
          memberType: item.memberType,
          permission: item.permission,
          type: item.type,
        }
        projectMemberList.push(obj)
      })
      if (this.project) {
        this.formsData = Object.assign(this.project, this.formsData)
      }
      // 如果是投资者
      if (this.appCode === 'lpInvestor') {
        let deepClone = _.cloneDeep(this.formsData)
        if (!this.formsData?.investorProfile) {
          this.formsData.investorProfile = deepClone.projectIntroduce
        }
        if (!this.formsData?.area) {
          this.formsData.area = this.areasParams.length === 0 ? null : this.areasParams
        }
        this.formsData.state = deepClone.currentState
        this.formsData['fullName'] = deepClone.companyFullName
        // delete this.formsData.currentState
        delete this.formsData.companyFullName
        delete this.formsData?.projectIntroduce
      } else if (this.appCode === 'investedCompany') {
        let deepClone = _.cloneDeep(this.formsData)
        if (!this.formsData?.introduction) {
          this.formsData.introduction = deepClone.projectIntroduce
        }
        delete this.formsData?.projectIntroduce
      }
      let params = {
        projectMemberList,
        area: this.isFof ? undefined : this.areasParams,
      }
         let newparams = {}
        if(this.from == 'shareDesk') {
          newparams = Object.assign(this.runtime_value, this.formsData, params, )
        } else {
         newparams = Object.assign(params, this.formsData)
        }
      if(!this.clickshow) {
        newparams.insightId = ''
      }
      newparams.currencyUnit = newparams.currencyUnit ? newparams.currencyUnit : this.baseCurrencyUnit;
      
      const fnParams = {
        ...newparams,
        projectMemberList,
        assetTypeId: this.isAssets ? this.parentView.id : undefined,
        creditCode: this.creditCode,
        brandId: this.brandId,
      }
      let res = null
      if(this.origFrom == 'shareDesk') {
        res = await beforeProjectShareAdd({
          data: {
            ...fnParams,
            shareRecord: {
              initiator: this.initiator , //测试数据项目原分享人
              shareInstanceId: this.shareInstanceId, //分享实例的ID
            }
          }
        }, this.createBCode,)
      } else {
        res = await beforeProjectAdd(
          {
            data: fnParams,
          },
          this.createBCode,
        )
      }

      if (!res) return this.$Message.error(this.$t('plat_c.app_c.dialog_add.chuangjianshibai'))

      if (this.chartFlag) {
        let chat = await chatCreate({
          params: {
            dataId: res,
            type: getDataTypeByBusinessCode(this.createBCode),
          },
        })
      }

      this.$Message.success(this.$t('plat_c.app_c.dialog_add.chuangjianchenggong'))
      this.loading = false
      this.visible = false // this.$emit('closeDialog')
      // 项目分享台账需要做特殊处理
      if(this.origFrom == 'shareDesk') {
        this.$emit('updateData', 1)
      } else {
        this.$emit('refreshTable')
        this.$emit('showDetails', { id: res }, this.createBCode)
      }
    },
    handleCancel() {
      this.visible = false
      this.$emit('closeDialog')
    },
    clenas() {
      this.searchValue = ''
      this.listcontent = []
      this.showbord = false
    },
  },
}
</script>
<style lang="scss">
.dialog {
  .arco-modal-header .arco-modal-title {
    justify-content: flex-start !important;
  }
  .arco-modal-body {
    padding: 0 !important;
  }
  .arco-modal-header {
    height: 50px;
    border: none;
  }
  .currline {
    width: 100% !important;
  }
}
</style>
<style lang="scss" scoped>
@import '@/assets/styles/mixin.scss';
.clenas {
  cursor: pointer;
}
.icbc {
  color: #e4e5eb;
  font-size: 12px;
  margin-left: 4px;
}

:deep(.edit-dialog-form) {
  // margin: 10px auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  .form-field-container {
    width: calc(50% - 15px);
    // margin-right: 10px;
    .arco-form-item {
      margin-bottom: 10px;
      .arco-form-item-content {
        width: 100%;
      }
    }
  }
}
.inputFouce {
  border: 1px solid #2676d0 !important;
}
.creatnomore {
  display: flex;
  // align-content: center;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - 88px);
  align-items: center;
  img {
    width: 80px;
    height: 80px;
  }
  p {
    font-size: 12px;
    width: 300px;
    text-align: center;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #c1c4cb;
  }
}
.twocontent {
  height: 327px;
  overflow: auto;
  padding: 12px 20px;
  :deep(.arco-form-item) {
    margin-bottom: 18px !important;
    .arco-form-item-label-col {
      margin-bottom: 0;
    }
    .arco-select-view-single {
      background: none;
      border: 1px solid #e4e5eb;
      border-radius: 6px;
    }
    .arco-select-view-error {
      border: 1px solid red;
      background: none;
    }
    .arco-form-item-wrapper-col .createdStaff .tag-select {
      border-radius: 6px;
    }
    .arco-textarea-wrapper, .uploader-trigger, .arco-picker {
      border-radius: 6px !important;
    }
    .arco-input-wrapper {
      background: none;
      padding: 1px 8px;
      border: 1px solid #e4e5eb;
      height: 30px;
      border-radius: 6px;
      &.arco-input-focus {
        border-color: #3272FE;
      }
      &:hover {
        background: none;
        border-color: #3272FE;
      }
      &.arco-input-error {
        border: 1px solid red;
        background: none;
      }
    }
    .arco-form-item-label {
      height: 20px;
      .field-label {
        height: 20px;
        line-height: 20px;
      }
    }
    &.arco-form-item-has-help {
      .currency-input {
        border: 1px solid red !important;
      }
    }
    .arco-picker {
      height: 30px;
    }
  }
}
.threecontent {
  height: 327px;
  :deep(.projectMen) {
    .batch-top-team-add-art {
      padding: 16px 20px;
      .batch-team-add-t {
        border-radius: 6px;
        height: 32px;
      }
    }
    .liebiao {
     top: 62px;
      .icbc {
        color: #2B2F36;
      }
      .top-team-title {
        height: 20px;
        padding: 0 20px;
      }
      .top-team-ul {
        padding: 10px 12px;
        .top-team-li {
          padding: 0 8px;
          border-radius: 6px;
          height: 48px;
          .top-team-li-r {
            font-size: 14px;
          }
          &:hover {
            background: #ECEEEE;
          }
          .top-team-li-rOption {
            border-radius: 6px;
            .el-dropdown-link {
              color: #1F2329;
              font-size: 14px;
            }
            &:hover {
              background: rgba(31, 35, 41, 0.08);
              .iconfont {
                color: #8F959E;
              }
            }
          }
        }
      }
    }
    
  }
}
.handleClose {
  width: 70px;
  height: 26px;
  padding: 0;
  // line-height: 26px;
  text-align: center;
  background: #ffffff;
  border-radius: 6px;
  margin-right: 17px;
  color: #3272FE;
  border: 1px solid #3272FE !important;
}
.content {
  // margin-top: 19px;
  height: 298px;

  .top {
    padding: 12px 24px;
    display: flex;
    align-items: center;
    padding-bottom: 9px;
  }
  .list {
    height: 250px;
    // padding-top: 13px;
    display: flex;
    justify-content: space-between;
    scrollbar-color: transparent transparent;
    scrollbar-track-color: transparent;
    -ms-scrollbar-track-color: transparent;
    ::-webkit-scrollbar {
      width: 0 !important;
    }
    // ::-webkit-scrollbar-thumb {
    //   width: 6px;
    //   height: 116px;
    //   background: #ececec;
    //   border-radius: 3px;
    // }
    // ::-webkit-scrollbar-track-piece {
    //   background: #fff;
    // }

    :deep(.arco-list-bordered) {
      border: none;
    }
    :deep(.arco-list-content) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      box-sizing: border-box;
      padding: 0 24px;
      &::after {
        content: '';
        width: 192px;
      }
    }
    :deep(.arco-list-split .arco-list-item:not(:last-child)) {
      border: none;
    }
    .list-item {
      &.actived {
        border: 1px solid #3272FE !important ;
      }
      &:hover {
        border: 1px solid #3272FE !important ;
      }
      // &.last-item {
      //   &:last-child{
      //     justify-content: flex-start;
      //   }
      // }
      // &:first-child {
      //   margin: 0;
      // }
      position: relative;
      padding: 12px;
      box-sizing: border-box;
      // height: 130px;
      // height: 104px;
      width: 192px;
      // margin-left: 8px;
      background-color: #ffffff;
      border-radius: 8px;
      // display: flex;
      margin-bottom: 8px;
      border: 1px solid #dbe0e3 !important;
      cursor: pointer;
      &.fof {
        height: 108px;
      }
      #triangle-topright {
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
        height: 0;
        border-top: 21px solid #3272FE;
        border-left: 21px solid transparent;
      }
      .choos {
        position: absolute;
        right: 1px;
        top: 1px;
        color: #fff;
        font-size: 10px;
      }
      .list-item-left {
        margin-right: 8px;
        display: flex;
        align-items: center;
        .showimg {
          width: 100%;
          width: 32px;
          height: 32px;
          border-radius: 6px;
          overflow: hidden;
          border-radius: 6px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          img {
            width: 100%;
            height: 100%;
          }
        }
        p {
          flex: 1;
          font-size: 14px;
          // margin-left: 8px;
          color: #1f2329;
          font-weight: 500;
          font-family: PingFangSC-Medium, PingFang SC;
          display: -webkit-box; /**对象作为伸缩盒子模型展示**/
          -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
          -webkit-line-clamp: 1; /**显示的行数**/
          overflow: hidden; /**隐藏超出的内容**/
        }
      }
      .list-item-right {
        .item-info {
          font-size: 12px;
          // height: 18px;
          line-height: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #4e5969;
          li {
            width: 168px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
        p {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666b71;
          display: -webkit-box; /**对象作为伸缩盒子模型展示**/
          -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
          -webkit-line-clamp: 1; /**显示的行数**/
          overflow: hidden; /**隐藏超出的内容**/
          margin: 4px 0;
        }
        p:nth-child(1) {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333b44;
        }
        p:nth-child(2),
        .tag {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #1f2329;
          span {
            display: inline-block;
            // border: 1px solid #e4e5eb;
            height: 18px;
            padding: 0 8px;
            line-height: 18px;
            border-radius: 9px;
            background-color: #fde9d1;
            font-family: PingFangSC-Medium, PingFang SC;
            max-width: 166px;
            // display: -webkit-box; /**对象作为伸缩盒子模型展示**/
            // -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
            // -webkit-line-clamp: 1; /**显示的行数**/
            overflow: hidden; /**隐藏超出的内容**/
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        p:nth-child(3),
        .desc {
          font-size: 12px;
          margin-top: 6px;
          height: 18px;
          line-height: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #4e5969;
          width: 100%;
          display: -webkit-box; /**对象作为伸缩盒子模型展示**/
          -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
          -webkit-line-clamp: 1; /**显示的行数**/
          overflow: hidden; /**隐藏超出的内容**/
          // @include ellipsis-oneline(210px);
          // @include ellipsis-lines(100%,1);
          // display: inline-block;
          // text-overflow: ellipsis;
          // white-space: nowrap;
          // overflow: hidden;
        }
      }
    }
  }
}
.add {
  .moreInfo {
    cursor: pointer;
    color: blue;
  }
  .asset-type-tag {
    font-size: 12px;
    display: inline-block;
    padding: 0 6px;
    height: 20px;
    line-height: 20px;
    background: #f2f4f6;
    border-radius: 4px;
    margin-left: 8px;
    color: #4e5969;
    font-weight: normal;
  }
}
.active {
  background: #3272FE !important;
  color: #fff !important;
}
.handleOk {
  width: 72px;
  height: 28px;
  background: #3272FE !important;
  color: #ffffff !important;
  text-align: center;
  // line-height: 28px;
  padding: 0;
  &:hover {
    background: #4c8bee !important;
  }
}
.tabschoose {
  // padding: 0 10px;
}
.zidngyifooter {
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .zidngyifooter-left {
    display: flex;
    align-items: center;
  }
}
.firststep {
  display: flex;
  align-items: center;
  color: #4e5969;

  span:nth-child(1) {
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    background: #f2f4f6;
    border-radius: 50%;
    // text-align: center;
    // line-height: 20px;
    color: #666b71;
    margin-right: 6px;
  }
  .gt {
    margin-left: 12px;
    margin-right: 12px;
    font-size: 12px;
    color: #c9cdd4;
    // margin-top: -1px;
  }
}
.contentIcon {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  font-style: normal;
}
.sizecolor {
  font-size: 12px;
  color: #3272FE;
}
</style>
