import moment from 'moment'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import { cusFormatDate } from '@/utils/cusFormatDate.js'

dayjs.extend(isToday)
dayjs.extend(isTomorrow)

export function formatSeason(date) {
  const num = parseInt(date);
  const year = moment(num).format('yyyy')
  const month = moment(num).format('MM-DD');
  let season = '';
  switch (month) {
    case '01-02':
      // 一月报
      season = '一月报'
      break
    case '02-02':
      // 二月报
      season = '二月报'
      break
    case '03-31':
      season = '一季报'
      break
    case '04-02':
      // 四月报
      season = '四月报'
      break
    case '05-02':
      // 五月报
      season = '五月报'
      break
    case '06-30':
      season = '半年报'
      break
    case '07-02':
      // 七月报
      season = '七月报'
      break
    case '08-02':
      // 八月报
      season = '八月报'
      break
    case '09-30':
      season = '三季报'
      break
    case '10-02':
      // 十月报
      season = '十月报'
      break
    case '11-02':
      // 十一月报
      season = '十一月报'
      break
    case '12-31':
      season = '年报'
      break
  }
  return `${year}年${season}`
}
// 将reportDate转换成季报对应的数字，一季报1.半年报：2，三季报3.年报4
export function formatReportType(date) {
  const num = parseInt(date);
  const year = moment(num).format('yyyy')
  const month = moment(num).format('MM-DD');
  let reportType = '';
  switch (month) {
    case '01-02':
      // 一月报
      reportType = 9
      break
    case '02-02':
      // 二月报
      reportType = 10
      break
    case '03-31':
      // 一季报
      reportType = 1
      break
    case '04-02':
      // 四月报
      reportType = 12
      break
    case '05-02':
      // 五月报
      reportType = 13
      break
    case '06-30':
      // 半年报
      reportType = 2
      break
    case '07-02':
      // 七月报
      reportType = 15
      break
    case '08-02':
      // 八月报
      reportType = 16
      break
    case '09-30':
      // 三季报
      reportType = 3
      break
    case '10-02':
      // 十月报
      reportType = 18
      break
    case '11-02':
      // 十一月报
      reportType = 19
      break
    case '12-31':
      // 年报
      reportType = 4
      break
  }
  return reportType
}

export function formatStatus(state, isReporter = false) {
  switch(state) {
    case 0:
      return '待提交'
    case 1:
      return isReporter ? '已提交' : '已确认'
    case 2:
      return isReporter ? '已提交' : '待确认'
    case 3:
      return '发送异常'
    case 4:
      return '逾期未提交'
    case 5:
      return isReporter ? '驳回' : '已驳回'
  }
}

export function formatDate(data) {
  const todayStart = moment().startOf('days');
  const todayEnd = moment().endOf('days');
  const todayStart1 = moment().startOf('days').subtract(1, 'days');
  const todayEnd1 = moment().endOf('days').subtract(1, 'days');
  const todayStart2 = moment().startOf('days').subtract(2, 'days');
  const todayEnd2 = moment().endOf('days').subtract(2, 'days');
  let dataTime = parseInt(data, 10);
  let year = moment().startOf('years');
  if(dataTime > todayStart && dataTime < todayEnd) {
    const timeFormat = 'HH:mm';
    return `${this.$t('plat_c.dataCollect.jintian')}${moment(dataTime).format(timeFormat)}`
  }else if(dataTime > todayStart1 && dataTime < todayEnd1) {
    const timeFormat = 'HH:mm';
    return `${this.$t('plat_c.dataCollect.zuotian')}${moment(dataTime).format(timeFormat)}`
  }
  // else if(dataTime > todayStart2 && dataTime < todayEnd2) {
  //   const timeFormat = 'HH:mm';
  //   return `前天${moment(dataTime).format(timeFormat)}`
  // }
  else if(dataTime < todayEnd2 && dataTime > year) {
    const timeFormat = 'M月D日';
    return `${moment(dataTime).format(timeFormat)}`
  } else if(year > dataTime) {
    const timeFormat = 'yy年M月D日';
    return `${moment(dataTime).format(timeFormat)}`
  } else if(moment(todayStart).diff(dataTime, 'days') < 1) {
    // 几天后失效
    return `${this.$t('plat_c.dataCollect.shixiao_tian', {day: (moment(dataTime).diff(todayStart, 'days') + 1)})}`
  }
}

export function failTimeTip(data){
  let target = data;
  if(!target) return '';
  let gap = target - new Date().getTime();
  if(gap < 0){
    return `${this.$t('plat_c.dataCollect.shixiaoyu')}${cusFormatDate(target * 1, false)}`;
  }
  if (dayjs(target * 1).isToday()) {
    if(gap >= 60 * 60 * 1000){ //大于1小时
      // XXX小时后失效
      return `${this.$t('plat_c.dataCollect.shixiao_xiaoshi', {hour: Math.floor( gap / (60 * 60 * 1000))})}`;
    } else {
      // XXX分钟后失效
      return `${this.$t('plat_c.dataCollect.shixiao_fenzhong', {minute: Math.floor( gap / (60 * 1000))})}`;
    }
  } else if (dayjs(target * 1).isTomorrow()) {
    // 明天失效
    return this.$t('plat_c.dataCollect.shixiao_mingtian');
  } else {
    return `${this.$t('plat_c.dataCollect.shixiao_tian', {day: Math.floor( gap / (24 * 60 * 60 * 1000))})}`;
  }
}