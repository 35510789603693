import { omit } from 'lodash'
import { currencyMappingStore } from '@/store/useCurrencyStore'
import Decimal from 'decimal.js';
export const formatSBNumberJson = (string: string, currency: string) => {
  string = string ?? '{}'
  const bigIntString = string.replace(/(\d{16,})/g, '"$1b"');
  try {
    const value =  JSON.parse(bigIntString, (key, v) => {

      if (typeof v === 'string' && v.endsWith('b')) {
        return BigInt(v.slice(0, -1));
      }
      return v;
    })
    if (currency && value?.currency) {
      value.currency = currency
    }
    return value
  } catch {
    return string
  }
}

export const formatSBJson = (string: string, currency: string) => {
  string = string ?? '{}'
  try {
    const value = JSON.parse(`${string}`)
    if (currency && value?.currency) {
      value.currency = currency
    }
    return value
  } catch {
    return string
  }
}

export function round(number: number, precision: number = 0) {
  const tmpNum = number.toLocaleString(undefined, { maximumSignificantDigits: 15 }).replaceAll(',', '')
  return Math.round(Number(`${tmpNum}e${precision}`)) / Math.pow(10, precision)
}
export function roundFloat(number: number, precision: number = 0) {
  const tmpNum = number.toLocaleString('en', { minimumFractionDigits: 15 }).replaceAll(',', '')
  if ((tmpNum+'').indexOf('e') > -1) {
    return Math.round(Number(`${tmpNum}e${precision}`)) / Math.pow(10, precision)
  } else {
    const intNum = (tmpNum+'').split('.')[0]
    let floatNum = (tmpNum+'').split('.')[1]
    if (floatNum.length > precision) {
      floatNum = floatNum.slice(0, 2)
    }
    return intNum + '.' + floatNum
  }
}

export function formatNumber(
  num: number | null | undefined,
  options?: {
    style?: 'percent' | 'currency'
    currency?: string
    minimumFractionDigits?: number
    maximumFractionDigits?: number
  },
  lang?: string,
) {
  const { getCurrencySymbol } = currencyMappingStore()
  
  const isEmpty = num === null || num === undefined
  if (isEmpty) {
    return '-'
  }
  // const symbol = options?.currency
  const symbol = options?.currency ? getCurrencySymbol(options?.currency) : options?.currency
  const isPercent = options?.style === 'percent'

  const formatted = num.toLocaleString(lang ?? 'zh', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 4,
    ...omit(options, ['currency', !isPercent ? 'style' : '']),
  })

  return `${symbol ? symbol + ' ' : ''}${formatted.replaceAll(/[a-zA-Z]/g, '')}`
}
export const handlePasteValue = (event:any) => {
  event.preventDefault();
  let clp = (event.originalEvent || event).clipboardData.getData('text');
  let values = clp.split('.');
  values[0] = values[0].replace(/,/g, '')
  values = values.join('.')
  let lastClp; 
  if (!['', null, undefined].includes(values) && isNaN(Number(values))) {
    lastClp = ''
  } else {
    lastClp = Number(values)
  }
  return lastClp;
}
export const investTypeMape = [
  { label: '领投', value: 1 },
  { label: '跟投', value: 2 },
]
export const transactionTypeMap:any = {
  1: '我方参与',
  11: '投资新股', // 
  12: '投资老股', //
  13: '行使股权认证', // 
  2:  '我方未参与', // 
  41: '退出',// 
  42: '分红',// ，
  43: '估值调整',//  
  44: 'ESOP',// 
  45: '变更注册资本',//  
  46: '其他',// 
  // 二级市场交易
  81: '股改',// 
  82: '上市',// 
  83: '投资/退出/分红/增股',// 
  31: '投资', //（上市）
  32: '退出', //（上市）
  33: '分红' ,//（上市）
  34: '赠股', // 
  84: '扩股',//  
  85: '退市',// 
  86: '其他', //
  121: '可转债投资', // 
  122: '还本/还息',
  21: '还本', //
  22: '还息', //
  123: '可转债转股', // 
}
export const transactionTypeColorMap:any = {
  1: '#D7E2FF',// 我方参与
  11: '#D2F7D2', // 投资新股
  12: '#DDEAFF', //投资老股
  13: '#EAE2FE', // 行使股权认证
  2:  '#FFE7D4', // 我方未参与
  41: '#DAF7F1',// 退出，
  42: '#FFE7D4',// 分红，
  43: '#FEDDDA',// 估值调整 
  44: '#D7E2FF',// ESOP
  45: '#D7E2FF',// 变更注册资本 
  46: '#D7E2FF',// 其他
  // 二级市场交易
  81: '#DAF7F1',// 股改，
  82: '#D7E2FF',// 上市，
  83: '#FFE7D4',// 投资/退出/分红/增股 
  31: '#D2F7D2', //投资（上市）
  32: '#DAF7F1', //退出（上市）
  33: '#FFE7D4' ,//分红（上市）
  34: '#EAE2FE', // 赠股
  84: '#D7E2FF',// 扩股 
  85: '#D7E2FF',// 退市 
  86: '#D7E2FF', //其他
  121: '#D7E2FF', // 可转债投资
  122: '#FFE7D4', // 还本/还息
  21: '#D2F7D2', //还本
  22: '#DAF7F1', //还息
  123: '#DAF7F1', // 可转债转股

}
export const transactionFofTypeMap:any = {
  1: '认缴',
  3: '分配', // 
  4: '估值调整', //
  5: '基金实缴调整', // 
  6:  '基金份额调整', // 
  2: '份额减持',// 
  7: '基金清算',// ，
  '-1': '其他',//  
}
export const transactionFofTypeColorMap:any = {
  1: '#E0E9FF',// 认缴
  3: '#FEE3E2', // 分配
  4: '#E0E9FF', //估值调整
  5: '#DAF7F1', // 基金实缴调整
  6:  '#DAF7F1', // 基金份额调整
  2: '#FEE7CD',// 份额减持
  7: '#FEE3E2',// 基金清算
  '-1': '#E0E9FF',// 其他 
}
export default formatNumber


// 'tag--add': [11, 31, ].includes(tagItem.type),
// 'tag--sub-add': [ 12,  34].includes(tagItem.type),
// 'tag--minus':  [41, 32].includes(tagItem.type),
// 'tag--divid': [42, 33].includes(tagItem.type),
// 'tag--other': [13].includes(tagItem.type),

// 'tag--add': [11, 31, ].includes(tagItem.type),
// 'tag--sub-add': [ 12,  34].includes(tagItem.type),
// 'tag--minus':  [41, 32].includes(tagItem.type),
// 'tag--divid': [42, 33].includes(tagItem.type),
// 'tag--other': [13].includes(tagItem.type),